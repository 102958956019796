@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

html,
body,
#app {
   height: 100%;
}
body {
   font-family: "Lato", sans-serif;
   margin: 0;
   padding: 0;
   background-color: hsla(0, 0%, 0%, 0.7);
   color: #fff;
}

* {
   box-sizing: border-box;
}

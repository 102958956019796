.header {
   display: flex;
   align-items: center;
   padding: 0 15px;
}

.header .info {
   margin-left: auto;
}

.header .info ul {
   border: 2px solid;
   padding: 0px;
   list-style: none;
   border-radius: 10px;
   background-color: #fff;
   color: #000;
   font-weight: bold;
   display: flex;
   padding: 0 7px;
}

.header ul > li {
   margin: 7px 0;
}

.header ul li:not(li:last-child)::after{
/* .header ul li:not(li:first-child, li:last-child)::before { */
   content: "|";
   padding: 5px;
}

.header .info img {
   vertical-align: middle;
   width: 35px;
   margin-right: 5px;
}

.container--bureaux {
   display: flex;
   flex-wrap: wrap;
   max-width: 1540px;
   margin: auto;
}

.box {
   text-align: center;
   min-width: 100px;
   padding: 25px 0px;
   font-size: 1.5em;
   border: 3px solid;
   margin: 14px;
   border-radius: 10px;
   background-color: #fff;
   color: #000;
   cursor: pointer;
   position: relative;
}

.box.box--arrive {
   background-color: hsl(49deg 100% 66%);
   color: hsl(49 70% 25%);
}
.box.box--arrive-mairie {
   background-color: hsl(112.8, 31.6%, 53.5%);
   color: hsl(112.8, 31.6%, 20%);
}
.box.box--nonarrive {
   background-color: hsl(0, 0%, 99%);
}

.box.box--erreur {
   background-color: hsl(0deg 100% 68%);
   color: hsl(0deg 100% 25%);
}

.box .box--numero{
   font-size: 1.5em;
   font-weight: 500;
}
.box .box--icon{
   width: 25px;
   position: absolute;
   left: 50%;
   bottom: 0%;
   transform: translateX(-50%);
}

.box .box--icon[src*="phone"]{
   filter: hue-rotate(255deg);
}

.container--popup {
   padding: 10px 20px;
   background-color: #333;
   border-radius: 10px;
}

.container--popup h3 {
   margin: 5px 0;
   text-align: center;
}

.container--popup .actions {
   display: flex;
   justify-content: space-around;
   gap: 10px;
}


.btn {
   border: none;
   padding: 5px 10px;
   cursor: pointer;
   border-radius: 5px;
}
.btn--icon{
   line-height: 0;
   border-radius: 20pxs;
   padding: 7px 14px;
   box-shadow: inset 0 0 0px 1px hsl(0deg 0% 50%);
   outline: none;
   border: 2px solid #fff;
   background-color: #fff;
}
.container--popup .popup--icon{
   width: 25px;
}

